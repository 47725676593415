<template>
  <contentCard titleText="我的订单">
    <div class="order-list">
      <div class="order-item" v-for="(item, index) in orderList" :key="'orderItem' + index" @click="orderOnClick(item, index)">
        <div class="order-header">订单编号：{{ item.orderId }}</div>
        <div class="order-body">
          <div class="order-info">
            <div class="label-value">
              <div class="label-text">订餐人：</div>
              <div class="value-text">{{ item.userName }}</div>
            </div>
            <div class="label-value">
              <div class="label-text">取餐地址：</div>
              <div class="value-text">{{ item.houseAddress }}</div>
            </div>
            <div class="label-value">
              <div class="label-text">下单时间：</div>
              <div class="value-text">{{ item.orderTime }}</div>
            </div>
          </div>
          <div class="order-price-quantity">
            <div class="price-text"><span>¥</span>{{ item.orderAmount }}</div>
            <div class="quantity-text">共{{ item.dishCount }}份菜品</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages-box">
      <van-pagination v-model="params.curPage" :total-items="total" :show-page-size="6" force-ellipses @change="pageOnChange">
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
    </div>
  </contentCard>
</template>

<script>
import storage from '../utils/storage.js';
import contentCard from '../components/contentCard.vue';
import { getMyOrderListUrl, userInfoURL } from './api.js';
import { mapState } from 'vuex';
import Vue from 'vue';
import { Pagination, Icon } from 'vant';

Vue.use(Pagination);
Vue.use(Icon);

export default {
  name: 'ytjEldCanMyOrderList',
  components: {
    contentCard
  },
  data() {
    return {
      total: 0,
      params: {
        curPage: 1,
        userId: '',
        tenantId: ''
      },
      orderList: []
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        curPage: this.params.curPage,
        pageSize: 6,
        userId: this.userId,
        tenantId: this.tenantId
      };
      this.$axios
        .get(getMyOrderListUrl, { params })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            this.orderList = res.data.records;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    pageOnChange() {
      this.getList();
    },
    orderOnClick(value, index) {
      this.$router.push({
        name: 'ytjEldCanMyOrderDetail',
        query: {
          id: value.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 40px 30px 40px;
}
.order-list {
  box-sizing: border-box;
  height: calc(100% - 50px);
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  .order-item {
    box-sizing: border-box;
    flex-shrink: 0;
    width: calc(33.3% - 14px);
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #f6f7fa;
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    max-height: calc(50% - 20px);
    padding: 12px 12px;
    .order-header {
      box-sizing: border-box;
      font-size: 16px;
      line-height: 22px;
      height: 28px;
      color: #2f3a4f;
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 6px;
    }
    .order-body {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: calc(100% - 28px);
      .order-info {
        box-sizing: border-box;
        flex-grow: 1;
        .label-value {
          box-sizing: border-box;
          font-size: 16px;
          line-height: 22px;
          color: #2f3a4f;
          display: flex;
          margin-bottom: 10px;
          .label-text {
            box-sizing: border-box;
            flex-shrink: 0;
            width: 90px;
            white-space: nowrap;
          }
        }
      }
      .order-price-quantity {
        box-sizing: border-box;
        flex-shrink: 0;
        margin-left: 5px;
        text-align: center;
        .price-text {
          font-size: 18px;
          line-height: 24px;
          color: #e02020;
          span {
            font-size: 14px;
          }
        }
        .quantity-text {
          font-size: 14px;
          line-height: 20px;
          color: #666666;
        }
      }
    }
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
}
.pages-box {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
