<template>
  <div class="container">
    <div class="content-card">
      <div class="content-header">
        <div class="handle-box left-handle">
          <div class="back-button" @click="$router.back()"></div>
        </div>
        <div class="title-text">{{ titleText }}</div>
        <div class="handle-box right-handle">
          <slot name="right-handle"></slot>
        </div>
      </div>
      <div class="content-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'container',
  props: {
    titleText: {
      type: String,
      default: '小亭食堂'
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  height: calc(100vh - 92px) !important;
  padding: 39px 24px 55px 39px;
  .content-card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #ffffff;
    .content-header {
      box-sizing: border-box;
      height: 86px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;
      .handle-box {
        box-sizing: border-box;
        flex-grow: 1;
        flex-shrink: 0;
      }
      .left-handle {
        .back-button {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          background: url('./img/back.png') no-repeat 50% 50%;
          background-size: 100% 100%;
          cursor: pointer;
          user-select: none;
        }
      }
      .title-text {
        box-sizing: border-box;
        flex-shrink: 0;
        font-size: 24px;
        color: #1874fd;
        font-weight: 700;
      }
      .right-handle {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.content-body {
  box-sizing: border-box;
  height: calc(100% - 86px);
  border-radius: 15px;
}
</style>
