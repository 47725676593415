// 查询 - 个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;

// 查询 - 小亭食堂 - 是否有权限
const getElderCanteenAuthUrl = `/gateway/hc-serve/miniapi/canteenPropertyFee/getPropertyFeePayStatus`;

// 查询 - 订餐 - 类型 - 列表
const getPlaceTypeListUrl = `/gateway/hc-serve/manageapi/canteenDishType/getDishTypeList`;

// 查询 - 订餐 - 列表
const getPlaceOrderListUrl = `/gateway/hc-serve/miniapi/canteenSaleDetail/getSaleDetailList`;

// 保存 - 订餐 - 信息
const savePlaceOrderInfoUrl = `/gateway/hc-serve/miniapi/canteenOrder/addInfo`;

// 查询 - 绑定房号 - 列表
const getHouseListUrl = `/gateway/hc-mini/user/mini/user-asset-list`;

// 查询 - 我的订单 - 列表
const getMyOrderListUrl = `/gateway/hc-serve/miniapi/canteenOrder/getOrderList`;

// 查询 - 订单 - 详情
const getMyOrderDetailUrl = `/gateway/hc-serve/miniapi/canteenOrder/getOrderDetailInfo`;

export {
  userInfoURL,
  getElderCanteenAuthUrl,
  getPlaceTypeListUrl,
  getPlaceOrderListUrl,
  savePlaceOrderInfoUrl,
  getMyOrderListUrl,
  getMyOrderDetailUrl,
  getHouseListUrl
};
